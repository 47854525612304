/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSettings } from './accountSettings';
import { Account } from './account';
import { AccountCapability } from './accountCapability';
import { TerminalReader } from './terminalReader';
import { AccountBusinessProfile } from './accountBusinessProfile';
import { BankAccount } from './bankAccount';


export interface AdminExtendedAccount { 
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The Tilled account type. Can be `partner` or `merchant`.
     */
    type: AdminExtendedAccount.TypeEnum;
    /**
     * The business name or individual name.
     */
    name?: string;
    /**
     * The primary user\'s email address.
     */
    email?: string;
    /**
     * Capabilities represent the assigned product codes to a given merchant account and their status.
     */
    capabilities?: Array<AccountCapability>;
    /**
     * Bank accounts attached to this account. Primarily used for payouts.
     */
    bank_accounts: Array<BankAccount>;
    /**
     * Terminal Readers attached to this account.
     */
    terminal_readers: Array<TerminalReader>;
    business_profile?: AccountBusinessProfile;
    settings?: AccountSettings;
    /**
     * The `partner` white label domain.
     */
    white_label_domain?: string;
    /**
     * Whether Tilled is providing support for this account.
     */
    merchant_support?: boolean;
    deleted_at?: string;
    parent_account?: Account;
    internal_metadata?: object;
}
export namespace AdminExtendedAccount {
    export type TypeEnum = 'partner' | 'merchant';
    export const TypeEnum = {
        PARTNER: 'partner' as TypeEnum,
        MERCHANT: 'merchant' as TypeEnum
    };
}


